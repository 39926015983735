<template>
  <div class="container">
    <section class="hero is-small is-primary">
      <div class="hero-body">
        <div class="columns is-vcentered is-multiline is-justify-content-center">
          <div class="column is-two-fifths">
            <b-image
              :src="clappyImage"
              alt="Clappy Logo"
              responsive
            ></b-image>
          </div>
          <div class="column is-7">
            <p class="title">Hi{{ computedUser ? ` ${computedUser.username}` : ', I\'m Clappy'}}!</p>
            <p class="subtitle is-size-6">Your friendly neighborhood movie watcher!</p>

            <div class="buttons" style="margin:0;">
              <b-button
                href="https://instagram.com/mafdiscord"
                target="_blank"
                tag="a"
                type="is-info"
                icon-left="instagram">Instagram</b-button>
              <b-button
                href="https://twitter.com/mafdiscord"
                target="_blank"
                tag="a"
                type="is-info"
                icon-left="twitter">Twitter</b-button>
            </div>
            <div class="buttons">
              <b-button
                href="https://letterboxd.com/mafdiscord/"
                target="_blank"
                tag="a"
                type="is-info"
                icon-left="dots-horizontal-circle">Letterboxd</b-button>
              <b-button
                href="https://storyiseverything.io/"
                target="_blank"
                tag="a"
                type="is-info"
                icon-left="movie-open-outline">Story Is Everything</b-button>
            </div>

            <a href="https://discord.gg/movies">
              <img src="https://discordapp.com/api/guilds/269145090016935936/widget.png?style=banner3&size=2048" />
            </a>
          </div>
        </div>
      </div>
    </section>

    <div class="py-6">
      <h1 class="is-size-3">FAQ</h1>
      <b-collapse
        class="card"
        animation="slide"
        v-for="(collapse, i) of collapses"
        :key="i"
        :open="faqOpen === i"
        @open="faqOpen = i">

        <template #trigger="props">
          <div class="card-header" role="button">
            <p class="card-header-title">{{ collapse.title }}</p>
            <a class="card-header-icon">
              <b-icon
                :icon="props.open ? 'menu-down' : 'menu-up'">
              </b-icon>
            </a>
          </div>
        </template>

        <div class="card-content">
          <div class="content">
            <p v-html="collapse.text"></p>
          </div>
        </div>
      </b-collapse>
    </div>

  </div>
</template>

<script>
import { getUser } from '@/api/users'

export default {
  data: () => ({
    faqOpen: '',
    collapses: [
      {
        title: 'What Is Clappy?',
        text: 'Clappy is Movies and Filmmaking custom bot that enhances what our members love the most. Clappy has major features for the server.'
      },
      {
        title: 'XP and Currency System.',
        text: 'Clappy replaces Mee6 for a more integrated system with our server. Your level is tied to the number of daily tickets you earn. The higher the rank, the more daily tickets you can claim. Levels are as follows. You level up and gain xp by talking in text-chat. There is no way to earn xp through voice chat.\n' +
          '@Film Reels (Level 1+):  25 Daily<br/>' +
          '@Laserdisc (Level 5+): 50 Daily<br/>' +
          '@VHS (Level 15+): 100 Daily<br/>' +
          '@DVD (Level 25) 150 Daily<br/>' +
          '@Blu Ray (Level 40) 250 Daily<br/>' +
          '@2K (Level 47) 300 Daily<br/>' +
          '@4K (Level 53) 400 Daily<br/>' +
          '@8K (Level 67) 650 Daily<br/>' +
          '@12K (Level 74) 850 Daily<br/>' +
          '@IMAX (Level 80) 1,250 Daily'
      },
      {
        title: 'Tickets',
        text: 'Tickets is the currency that Clappy uses to buy items on the item store. These tickets can be claimed through /daily (resets every 24 hours), /vcclaim (resets every 15 minutes in voice chat), Clappy VIP (gifted monthly) or through events.'
      },
      {
        title: 'Items & Clappy Customization.',
        text: 'These items can be shopped for either through the /store command or through the https://mafdiscord.com/ website. You can see your current ticket number and use these tickets to buy different items and equip them on Clappy. Different items for the head, body, arms, etc… Make your own Clappy with these items! Your tickets and custom Clappy will be seen anytime you use the bot like /profile.'
      },
      {
        title: 'Letterboxd Integration',
        text: 'Clappy will be fully replacing the letterboxd bot for our own streamlined version. Using the /letterboxd film command it will pull up any movie just like the classic Letterboxd did. You may also use /letterboxd random to get a random film posted to you. All hyperlinks that Clappy uses link to their respective Letterboxd page.'
      },
      {
        title: 'Letterboxd Profile',
        text: 'Clappy will now link your letterboxd profile to your discord Clappy profile using /letterboxd setusername. This will pull your letterboxd link and four favorite movies onto your /profile. Anyone can see your letterboxd profile through the /profile command if you choose to set it.'
      },
      {
        title: 'Movie Recommendations',
        text: 'Everyone on the server now has the ability to recommend, rate, and view your recommendations inbox through clappy. To recommend a movie, type /letterboxd film, name of the movie, then hit the recommendation command (on desktop click on the recommendation option) (on mobile tap on recommendation), then mention the user. It should look something like this /letterboxd film name:LaLaLand recommendation: @Wumpus.'
      },
      {
        title: 'Recommendations Inbox',
        text: 'You can look at this movies that are recommended to you in /inbox. You may also turn on or off movie recommendations with /inbox state (receive for on, block for off). Typing in /inbox will show you the movies recommended to you, by whom, and what time.'
      },
      {
        title: 'Rating Recommendations',
        text: 'Using the /rate command, you can see all the movies recommended to you and rate them via a button 1-5 scale. There is no half stars and these do NOT link to letterboxd ratings.\n'
      },
      {
        title: 'Outbox',
        text: 'Using the /outbox command you can see all the movies you recommended to others. You can also see if they rated the films you recommended.'
      },
      {
        title: 'Boosts',
        text: 'Boosts are purchasable items that will allow you to earn more tickets through the daily command (lasts for a week) or earn more xp (lasts for a day).'
      }
    ]
  }),
  computed: {
    clappyImage () {
      if (this.computedUser && this.computedUser.settings.clappyImageUrl) return this.computedUser.settings.clappyImageUrl
      return 'https://cdn.discordapp.com/attachments/796094456129388544/875886301885395024/file.png'
    },
    computedUser () {
      return (this.$store.state.user.user && this.$store.state.user.user.id) ? this.$store.state.user.user : null
    }
  },
  methods: {
    async fetchUser () {
      await this.$store.dispatch('user/setUser', await getUser())
    }
  },
  mounted () {
    if (!this.computedUser) { this.fetchUser() }
  }
}
</script>
